import { Icon } from "@components/Icons"
import { useStores } from "@hooks/use-stores"
import translate from "@src/i18n"
import { Button, Form, Input, Progress } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import React, { useState } from "react"
import { useIntl } from "react-intl"

import styles from "../../../style.module.less"

const CreateNewPassword = ({
  handleClose,
  setSuccessMessage,
  phoneNumber,
  setStep,
}) => {
  const intl = useIntl()

  const [form] = Form.useForm()

  const { authSite } = useStores()

  const { changePassword } = authSite

  const [progress, setProgress] = useState(33)
  const [completedStyle, setCompletedStyle] = useState("")
  const [confirmCompleted, setConfirmCompleted] = useState(false)
  const [newPassword, setNewPassword] = useState()

  const VALIDATE_MESSAGES = {
    required: intl.formatMessage({ id: "requiredInput" }),
    string: {
      min: intl.formatMessage({ id: "messageErrorPassword" }),
    },
  }

  const handleChangePassword = () => {
    form.resetFields()
    setStep(1)
    handleClose()
  }

  const handleSubmit = async () => {
    const res = await changePassword({
      password: newPassword,
      phoneNumber: phoneNumber,
    })

    if (_get(res, "status") === 200) {
      setSuccessMessage(translate("changePasswordSuccess"))
      handleClose()
      return
    }
  }

  const progressBar = {
    33: {
      color: "exception",
      text: intl.formatMessage({ id: "bad" }),
    },
    66: {
      color: "active",
      text: intl.formatMessage({ id: "medium" }),
    },
    100: {
      color: "success",
      text: intl.formatMessage({ id: "excelent" }),
    },
  }

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue("newPassword") === value) {
        setConfirmCompleted(true)
        return Promise.resolve()
      }

      return Promise.reject(intl.formatMessage({ id: "passwordReject" }))
    },
  })

  const progressCount = (value) => {
    setNewPassword(value)
    const passwordLength = value.length

    if (passwordLength <= 3) {
      setProgress(33)
      return
    }

    if (passwordLength <= 6) {
      setProgress(66)
      return
    }

    setProgress(100)
    return
  }

  const handleBlur = (e) => {
    if (e.target.value) {
      setCompletedStyle(styles.completedInput)
    }
  }

  return (
    <div className={cx("changePasswordForm", styles.mainContainer)}>
      <span className={styles.modalTitle}>{translate("changePassword")}</span>
      <p className={cx(styles.descriptionContainer, styles.margin)}>
        <span>{translate("changePassword.description")}</span>
      </p>
      <div className={cx(styles.label)}>
        <span>{translate("newPassword")}</span>
      </div>

      <Form
        form={form}
        onFinish={handleChangePassword}
        validateMessages={VALIDATE_MESSAGES}
      >
        <Form.Item
          name="newPassword"
          validateStatus={"error"}
          validateFirst
          rules={[
            {
              type: "string",
              required: true,
              min: 6,
            },
            {
              pattern:
                /^(?=.*[0-9])(?=.*[a-zA-Z])([A-Za-z\d$@$!%*#=+()^?&\-_'"`~ç{}[\]\\/,.]+)$/,
              message: intl.formatMessage({ id: "passwordError" }),
            },
          ]}
        >
          <Input.Password
            onChange={(e) => progressCount(e.target.value)}
            iconRender={(visible) =>
              visible ? <Icon name="EyeOpen" /> : <Icon name="EyeClose" />
            }
            className={completedStyle}
            onBlur={handleBlur}
          />
        </Form.Item>
        <div className="flex flex-row">
          <Progress
            showInfo={false}
            percent={progress}
            status={progressBar[progress].color}
          />
          <span className="dusty-gray ml12">{progressBar[progress].text}</span>
        </div>
        <div className={cx(styles.label)}>
          <span>{translate("confirmNewPassword")}</span>
        </div>

        <Form.Item
          name="confirmNewPassword"
          validateStatus={"error"}
          rules={[validateConfirmPassword]}
          initialValue=""
          shouldUpdate={(currentValues) => currentValues.newPassword}
        >
          <Input.Password
            iconRender={(visible) =>
              visible ? <Icon name="EyeOpen" /> : <Icon name="EyeClose" />
            }
            className={confirmCompleted ? completedStyle : ""}
            onBlur={handleBlur}
          />
        </Form.Item>

        <Button
          className={styles.button}
          type="primary"
          htmlType="submit"
          onClick={handleSubmit}
        >
          {translate("save")}
        </Button>
      </Form>
    </div>
  )
}

export default CreateNewPassword
