import "./style.module.less"

import { CodeSandboxOutlined } from "@ant-design/icons"
import { CustomModalErrors } from "@components/CustomModalErrors"
import TranslateWrapper from "@components/TranslateWrapper"
import { Row } from "antd"
import cx from "classnames"
import React, { useState } from "react"

import AuthStep from "./components/AuthStep"
import NewAccountStep from "./components/NewAccountStep"
import NewPasswordStep from "./components/NewPasswordStep"
import PhoneStep from "./components/PhoneStep"
import styles from "./style.module.less"

const ForgotPassword = ({
  areaCode,
  setVisible,
  setSuccessMessage,
  isProfile,
  data,
  isSubscriber,
}) => {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [errors, setErrors] = useState([])
  const [code, setCode] = useState("")

  const handleResetSteps = () => {
    setStep(1)
  }

  const switchRender = (step) => {
    if (!isProfile) {
      switch (step) {
        case 1:
          return (
            <PhoneStep
              setStep={setStep}
              areaCode={areaCode}
              loading={loading}
              setLoading={setLoading}
              setPhoneNumber={setPhoneNumber}
              setErrors={setErrors}
            />
          )

        case 2:
          return (
            <NewAccountStep
              handleResetSteps={handleResetSteps}
              phoneNumber={phoneNumber}
            />
          )

        case 3:
          return (
            <AuthStep
              handleResetSteps={handleResetSteps}
              phoneNumber={phoneNumber}
              loading={loading}
              setLoading={setLoading}
              setStep={setStep}
              setErrors={setErrors}
              setCode={setCode}
            />
          )

        case 4:
          return (
            <NewPasswordStep
              phoneNumber={phoneNumber}
              handleResetSteps={handleResetSteps}
              setVisible={setVisible}
              setErrors={setErrors}
              setLoading={setLoading}
              setSuccessMessage={setSuccessMessage}
              code={code}
            />
          )

        default:
          return null
      }
    } else {
      switch (step) {
        case 1:
          return (
            <PhoneStep
              setStep={setStep}
              areaCode={areaCode}
              loading={loading}
              setLoading={setLoading}
              setPhoneNumber={setPhoneNumber}
              setErrors={setErrors}
              isProfile
            />
          )
        case 3:
          return (
            <AuthStep
              handleResetSteps={handleResetSteps}
              phoneNumber={phoneNumber}
              loading={loading}
              setLoading={setLoading}
              setStep={setStep}
              setErrors={setErrors}
              isProfile
              data={data}
              setVisible={setVisible}
              setSuccessMessage={setSuccessMessage}
              isSubscriber={isSubscriber}
              setCode={setCode}
            />
          )
      }
    }
  }

  return (
    <TranslateWrapper>
      {!isProfile ? (
        <div className={cx("forgotPasswordContext", styles.mainContainer)}>
          {switchRender(step)}
        </div>
      ) : (
        <div className={cx("forgotPasswordContext", styles.wrapper)}>
          {switchRender(step)}
        </div>
      )}

      {errors.length ? (
        <CustomModalErrors errors={errors} setErrors={setErrors} />
      ) : null}
    </TranslateWrapper>
  )
}

export default ForgotPassword
