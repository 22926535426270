import { useStores } from "@hooks/use-stores"
import ForgotPassword from "@modules/MyAccount/ForgotPassword"
import translate from "@src/i18n"
import React, { useState } from "react"

import CreateNewPassword from "./CreateNewPassword"
import ValidatePassword from "./ValidatePassword"

const ChangePasswordForm = ({
  handleClose,
  setModalContent,
  data,
  setSuccessMessage,
  areaCode,
}) => {
  const { authSite } = useStores()

  const { changePasswordProfile } = authSite

  const [step, setStep] = useState(1)
  const [password, setPassword] = useState()

  const [passwordError, setPasswordError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleLogin = async () => {
    setLoading(true)
    setPasswordError(null)
    const response = await changePasswordProfile({
      phoneNumber: data.phoneNumber,
      password: password,
    })

    if (response === false) {
      setStep(1)
      setPasswordError("Senha inválida")
      setLoading(false)
    } else {
      setStep(2)
      setLoading(false)
    }
  }

  const ForgotPasswordLink = () => {
    return (
      <a
        onClick={() => setModalContent(<ForgotPassword areaCode={areaCode} />)}
      >
        {translate("forgotPassword")}
      </a>
    )
  }

  return step === 1 ? (
    <ValidatePassword
      handleLogin={handleLogin}
      passwordError={passwordError}
      loading={loading}
      password={password}
      setPassword={setPassword}
      ForgotPasswordLink={ForgotPasswordLink}
    />
  ) : (
    <CreateNewPassword
      handleClose={handleClose}
      setSuccessMessage={setSuccessMessage}
      phoneNumber={data.phoneNumber}
      setStep={setStep}
    />
  )
}

export default ChangePasswordForm
