import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { Button, Col } from "antd"
import cx from "classnames"
import { Link } from "gatsby"
import React from "react"

import styles from "../style.module.less"

const NewAccountStep = ({ phoneNumber, handleResetSteps }) => {
  const phoneWithoutCountryCode = phoneNumber.substring(
    phoneNumber.indexOf("(") + 1
  )

  return (
    <>
      <div className="tc flex flex-column">
        <span className={cx(styles.form_title)}>
          {translate("numberWithoutRegister")}
        </span>

        <span className={cx(styles.form_subtitle, styles.containerSize)}>
          {translate("numberWithoutRegister.description")}
        </span>
        <Button
          type="text"
          className="flex mb24 self-center"
          onClick={handleResetSteps}
        >
          <span className="french-rose f14 fw5 mr8">{phoneNumber}</span>

          <Icon name="EditPink" />
        </Button>
      </div>

      <Link
        to="/cadastro-assinante"
        state={{ phoneNumber: phoneWithoutCountryCode }}
        className={cx(
          "ant-btn ant-btn-primary ant-btn-block self-center fw6 f16 items-center justify-center",
          styles.buttonCreateAccount,
          styles.buttonAccount
        )}
      >
        {translate("titleSubscriber")}
      </Link>

      <Link
        to="/cadastro-criador"
        state={{ phoneNumber: phoneWithoutCountryCode }}
        className={cx(
          "ant-btn ant-btn-block b self-center fw6 f16 secondaryButton  items-center justify-center",
          styles.buttonCreateAccount,
          styles.buttonCreateAccountCreator,
          styles.buttonAccount
        )}
      >
        {translate("titleUser")}
      </Link>
    </>
  )
}

export default NewAccountStep
