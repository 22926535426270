import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { Button, Form, Input } from "antd"
import cx from "classnames"
import React, { useState } from "react"

import styles from "../../../style.module.less"

const ValidatePassword = ({
  ForgotPasswordLink,
  handleLogin,
  passwordError,
  loading,
  password,
  setPassword,
}) => {
  const [completedStyle, setCompletedStyle] = useState("")

  const handleBlur = (e) => {
    if (e.target.value) {
      setCompletedStyle(styles.completedInput)
    }
  }

  return (
    <div className={cx("changePasswordForm", styles.mainContainer)}>
      <span className={styles.modalTitle}>{translate("changePassword")}</span>
      <p className={cx(styles.descriptionContainer)}>
        <span>{translate("passwordMessage")}</span>
      </p>

      <div className={styles.container}>
        <span className={cx(styles.password)}>{translate("password")}</span>
        <span className={styles.recoverLink}>{<ForgotPasswordLink />}</span>
      </div>

      <Form>
        <Form.Item
          help={<div>{passwordError}</div>}
          validateStatus={passwordError && "error"}
        >
          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            iconRender={(visible) =>
              visible ? <Icon name="EyeOpen" /> : <Icon name="EyeClose" />
            }
            className={completedStyle}
            onBlur={handleBlur}
          />
        </Form.Item>
      </Form>

      <Button
        className={styles.button}
        type="primary"
        onClick={handleLogin}
        loading={loading}
      >
        {translate("continue")}
      </Button>
    </div>
  )
}

export default ValidatePassword
