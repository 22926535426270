import { Icon } from "@components/Icons"
import { useStores } from "@hooks/use-stores"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import translate from "@src/i18n"
import { getOnlyDigits } from "@utils/strings"
import { Button, Col, Form, Input, Progress } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import React, { useState } from "react"
import { useIntl } from "react-intl"

import styles from "../style.module.less"

const NewPasswordStep = ({
  phoneNumber,
  setVisible,
  setErrors,
  setLoading,
  setSuccessMessage,
  code,
}) => {
  const [progress, setProgress] = useState(33)

  const intl = useIntl()

  const { authSite } = useStores()

  const { changePassword } = authSite

  const VALIDATE_MESSAGES = {
    required: intl.formatMessage({ id: "requiredInput" }),
    string: {
      min: intl.formatMessage({ id: "messageErrorPassword" }),
    },
  }

  const handleSubmit = async (values) => {
    setLoading(true)

    const res = await changePassword({
      password: values.newPassword,
      phoneNumber: getOnlyDigits(phoneNumber),
      token: code,
    })

    setLoading(false)

    if (_get(res, "status") === 200) {
      setVisible(false)
      setSuccessMessage(intl.formatMessage({ id: "changePasswordSuccess" }))
      return
    }

    const adaptedErrors = errorsAdapter(res)
    setErrors(adaptedErrors)
  }

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (value) {
        if (getFieldValue("newPassword") !== value) {
          return Promise.reject(intl.formatMessage({ id: "passwordReject" }))
        }
      }

      return Promise.resolve()
    },
  })

  const progressBar = {
    33: {
      color: "exception",
      text: translate("bad"),
    },
    66: {
      color: "active",
      text: translate("medium"),
    },
    100: {
      color: "success",
      text: translate("excelent"),
    },
  }

  const progressCount = (value) => {
    const passwordLength = value.length

    if (passwordLength <= 3) {
      setProgress(33)
      return
    }

    if (passwordLength <= 6) {
      setProgress(66)
      return
    }

    setProgress(100)
    return
  }

  return (
    <>
      <div className={cx("newPasswordContext", styles.descriptionContainer)}>
        <span className={cx(styles.form_title)}>
          {translate("changePassword")}
        </span>

        <span className={cx("", styles.form_subtitle, styles.widthContainer)}>
          {translate("changePassword.description")}
        </span>

        <Form
          className="formNewPassword"
          onFinish={handleSubmit}
          validateMessages={VALIDATE_MESSAGES}
          layout="vertical"
          initialValues={{
            newPassword: "",
            confirmNewPassword: "",
          }}
        >
          <div className={styles.form_label}>
            <span> {translate("newPassword")}</span>
          </div>
          <Form.Item
            name="newPassword"
            validateFirst
            validateStatus={"error"}
            rules={[
              {
                type: "string",
                required: true,
                min: 6,
              },
              {
                pattern:
                  /^(?=.*[0-9])(?=.*[a-zA-Z])([A-Za-z\d$@$!%*#=+()^?&\-_'"`~ç{}[\]\\/,.]+)$/,
                message: intl.formatMessage({ id: "passwordError" }),
              },
            ]}
            style={{ marginBottom: "0px" }}
          >
            <Input.Password
              onChange={(event) => progressCount(event.target.value)}
              iconRender={(visible) =>
                visible ? <Icon name="EyeOpen" /> : <Icon name="EyeBlocked" />
              }
            />
          </Form.Item>

          <div className="flex flex-row mt8">
            <Progress
              className="mb16"
              showInfo={false}
              percent={progress}
              status={progressBar[progress].color}
            />

            <span className="dusty-gray ml12">
              {progressBar[progress].text}
            </span>
          </div>
          <div className={styles.form_label}>
            <span> {translate("confirmNewPassword")}</span>
          </div>
          <Form.Item
            name="confirmNewPassword"
            validateStatus={"error"}
            rules={[{ required: true }, validateConfirmPassword]}
            style={{ marginBottom: 0 }}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <Icon name="EyeOpen" /> : <Icon name="EyeClose" />
              }
            />
          </Form.Item>
          <Button
            className={cx(styles.buttonSize, styles.mtButton)}
            type="primary"
            htmlType="submit"
          >
            {translate("save")}
          </Button>
        </Form>
      </div>
    </>
  )
}

export default NewPasswordStep
