import { Icon } from "@components/Icons"
import { useStores } from "@hooks/use-stores"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import translate from "@src/i18n"
import { maskPhone } from "@utils/strings"
import { getOnlyDigits } from "@utils/strings"
import { Button, Col, Form, Input, Select, Tooltip } from "antd"
import cx from "classnames"
import _map from "lodash/map"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import styles from "../style.module.less"

const PhoneStep = ({
  areaCode,
  setStep,
  loading,
  setLoading,
  setPhoneNumber,
  setErrors,
  isProfile,
}) => {
  const [phoneError, setPhoneError] = useState(null)
  const [phone, setPhone] = useState("")

  const intl = useIntl()

  const { Option } = Select

  const { authSite } = useStores()

  const [form] = Form.useForm()

  const { forgotPasswordValidatePhone, sendCode } = authSite

  const validateMessages = {
    required: intl.formatMessage({ id: "requiredInput" }),
  }

  useEffect(() => {
    form.setFieldsValue({ phone: maskPhone(phone) })
  }, [phone])

  const handleSubmit = async (values) => {
    const { codeNumber, phone } = values

    const phoneNumber = getOnlyDigits(`${codeNumber}${phone}`)

    setPhoneNumber(`+${codeNumber} ${phone}`)

    setLoading(true)

    if (!isProfile) {
      const isValid = await forgotPasswordValidatePhone({ phoneNumber })

      setLoading(false)

      if (!isValid) {
        setStep(2)
        return
      }
    }

    setLoading(true)

    const res = await sendCode({ phone: phoneNumber, toResetPassword: true })

    setLoading(false)

    if (res.status === 200) {
      setStep(3)
      return
    }

    const adaptedErrors = errorsAdapter(res)
    setErrors(adaptedErrors)
  }

  const handleChangePhoneNumber = (e) => {
    let value = e.target.value
    setPhone(value)
  }

  const validatePhone = () => ({
    async validator(rule, value) {
      if (value) {
        value = maskPhone(value)

        const regexPhoneNumber = new RegExp(
          /^(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/
        )
        // const regexPhoneNumber = new RegExp(/^(\(\d{2}\)) (\d{4,5})-(\d{4})$/)  para wpp c/ fixo

        if (!regexPhoneNumber.test(value)) {
          setPhoneError(intl.formatMessage({ id: "errorPhoneNumber" }))
          return Promise.reject(intl.formatMessage({ id: "errorPhoneNumber" }))
        }
        setPhoneError(null)
        return Promise.resolve()
      }

      setPhoneError("Campo obrigatório")
      return Promise.reject()
    },
  })

  return (
    <>
      <div className={styles.descriptionContainer}>
        <span className={cx(styles.form_title)}>
          {!isProfile ? translate("newPassword") : translate("changePhone")}
        </span>

        <span className={cx(styles.form_subtitle, styles.subTitleMargin)}>
          {!isProfile
            ? translate("forgotMyPassword.description")
            : translate("phoneMessage")}
        </span>
      </div>

      <div className={styles.container}></div>

      <Form
        layout="vertical"
        validateMessages={validateMessages}
        onFinish={handleSubmit}
        initialValues={{
          codeNumber: "55",
          phone: "",
        }}
        form={form}
      >
        <div className={cx("flex", styles.inputContainer)}>
          <div className={cx("w-30", styles.containerPhoneLabels)}>
            <span className={cx(styles.form_label)} style={{ marginTop: 5 }}>
              {translate("country")}
            </span>
            <Form.Item name="codeNumber" rules={[{ required: true }]}>
              <Select
                showSearch
                className=""
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                loading={!areaCode}
              >
                {_map(areaCode, (item) => (
                  <Option key={item.id} value={item.code}>
                    {`+${item.code}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className={cx("w-100", styles.containerPhoneLabels)}>
            <div className={cx(styles.labelWhatsapp)}>
              <label className={styles.form_label}>
                {translate("whatsapp")}
              </label>
              <label className={cx("i scorpion-main", styles.customLabelTwo)}>
                {translate("digitNine")}
              </label>
              <Tooltip color="#ffffff">
                <Icon name="TooltipInfo" />
              </Tooltip>
            </div>
            <Form.Item
              name="phone"
              rules={[{ type: "string", required: true }, validatePhone]}
              className="w-100 tl"
              style={{ marginBottom: 0 }}
              validateStatus={phoneError && "error"}
              help={<div className="f12">{phoneError}</div>}
              validateTrigger="onBlur"
            >
              <Input
                value={phone}
                onChange={handleChangePhoneNumber}
                className=" w-100"
                disabled={loading}
                maxLength={15}
              />
            </Form.Item>
          </div>
        </div>
        <Button
          type="primary"
          className={cx(
            "self-center fw6 f16",
            styles.buttonSize,
            styles.button
          )}
          htmlType="submit"
          loading={loading}
        >
          {translate("save")}
        </Button>
      </Form>
    </>
  )
}

export default PhoneStep
